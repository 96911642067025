import React, { useState } from "react"
import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { color as csxColor } from "csx"
import { StaticImage } from "gatsby-plugin-image"
import { useInView } from "react-intersection-observer"

import { color, width, mq, fontFamily } from "../../utils/style-components"
import { ReactComponent as Logo } from "../../img/common/logo.svg"
import More from "../common/more"

const Point: React.FC = ({ children }) => {
  const { ref, inView } = useInView({
    threshold: 0.05,
    triggerOnce: true,
  })
  let classActive = inView ? `pointInView` : `pointOutView`
  return (
    <div
      ref={ref}
      className={classActive}
      css={css`
        max-width: ${width.contentInner};
        position: relative;
        ${mq(
          `pc`,
          css`
            margin: 220px auto 0;
            background: ${color.white};
          `
        )}
        ${mq(
          `sp`,
          css`
            margin: 18vw -${width.paddingSP} 0;
          `
        )}
        &:nth-of-type(n+2) {
          margin-top: min(130px, 50vw);
        }
      `}
    >
      {children}
    </div>
  )
}

const Signboard = styled.div`
  color: ${color.white};
  position: absolute;
  top: -0.5em;
  font-family: ${fontFamily.decorate1};
  line-height: 1;
  font-weight: bold;
  transition: 1.5s cubic-bezier(0.17, 0.84, 0.44, 1);
  transition-property: top, left, text-shadow;
  .pointOutView & {
    top: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.56);
    ${mq(
      `pc`,
      css`
        left: 0;
      `
    )}
  }
  ${mq(
    `pc`,
    css`
      font-size: 7rem;
      left: max(-30px, ${(width.paddingSPNumber * -1) / 2}vw);
      text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
    `
  )}
  ${mq(
    `sp`,
    css`
      font-size: 3rem;
      left: ${width.paddingSPNumber / 2}vw;
      text-shadow: 2px 1px 2px rgba(0, 0, 0, 0.4);
    `
  )}
`
const PointTitle = styled.h2`
  position: absolute;
  top: -48px;
  max-width: 605px;
  width: 88%;
  line-height: 1.6;
  color: ${color.white};
  font-weight: normal;
  letter-spacing: 0.1em;
  background: ${color.blue};
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.23);
  z-index: 12;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  transition: 1s cubic-bezier(0.17, 0.84, 0.44, 1);
  transition-property: top, left, box-shadow;
  .pointOutView & {
    top: 0;
    left: 0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.63);
  }
  ${mq(
    `pc`,
    css`
      font-size: 2.4rem;
      padding: 50px 70px;
      left: max(-48px, ${(width.paddingSPNumber * -1) / 2}vw);
    `
  )}
  ${mq(
    `sp`,
    css`
      font-size: min(2.3rem, 4.16vw);
      padding: 1.8em 2.5em;
      left: 0;
    `
  )}
`
const pointImageStyle = css`
  ${mq(
    `pc`,
    css`
      height: 100%;
      width: auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    `
  )}
  ${mq(
    `sp`,
    css`
      width: 100vw;
      height: auto;
      max-height: 390px;
    `
  )}
`
const PointDetail = styled.div`
  ${mq(
    `pc`,
    css`
      position: relative;
      z-index: 11;
      min-height: 420px;
      width: 60%;
      padding-left: 7%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: linear-gradient(
        90deg,
        ${color.white},
        ${color.white} 70%,
        ${csxColor(color.white).fade(0).toString()}
      );
    `
  )}
  ${mq(
    `sp`,
    css`
      margin: 0 ${width.paddingSP};
      margin-left: 10vw;
    `
  )}
`
const PointList = styled.ul`
  list-style: none;
  ${mq(
    `pc`,
    css`
      margin-top: 3em;
    `
  )}
  ${mq(
    `sp`,
    css`
      margin-left: 0.5em;
    `
  )}
  & > li {
    position: relative;
    padding-left: 1.5em;
    ${mq(
      `pc`,
      css`
        font-size: 2rem;
      `
    )}
    ${mq(
      `sp`,
      css`
        font-size: min(2rem, 4.37vw);
        line-height: 1.6;
        margin-top: 0.8em;
        padding-right: ${width.paddingSP};
      `
    )}
    &::before {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      content: "";
      width: 0.5em;
      height: 1em;
      border-right: solid 3px ${color.blue};
      border-bottom: solid 3px ${color.blue};
      transform: translateY(-50%) rotate(45deg);
    }
  }
`
const moreStyle = css`
  ${mq(
    `pc`,
    css`
      margin-top: 1.5em;
    `
  )}
  ${mq(
    `sp`,
    css`
      margin-top: 2em;
    `
  )}
`

const Points: React.FC = () => {
  return (
    <div
      css={css`
        margin: min(230px, 50vw) 0 0;
        padding: 0 0 min(180px, 27vw);
      `}
    >
      <Point>
        <PointTitle>
          <Signboard>WHY?</Signboard>
          なぜ八丈島でSDGsなのか？
        </PointTitle>
        <StaticImage
          src="../../img/top/intro_why.jpg"
          width={736}
          height={420}
          alt=""
          css={pointImageStyle}
        />
        <PointDetail>
          <PointList>
            <li>Our Mission</li>
            <li>日本における離島の重要性</li>
            <li>八丈島のポテンシャル</li>
          </PointList>
          <More to="/why" css={moreStyle} />
        </PointDetail>
      </Point>
      <Point>
        <PointTitle>
          <Signboard>OUR VALUES</Signboard>
          私達のコアバリュー
        </PointTitle>
        <StaticImage
          src="../../img/top/intro_out_values.jpg"
          width={736}
          height={420}
          alt=""
          css={pointImageStyle}
        />
        <PointDetail>
          <PointList>
            <li>フルリモートアイランドワークにおけるQOLの実現</li>
            <li>離島におけるサーキュラーエコノミーの実現</li>
            <li>「Zero Emission」への挑戦</li>
          </PointList>
          <More to="/values" css={moreStyle} />
        </PointDetail>
      </Point>
    </div>
  )
}

const Introduction: React.FC = () => {
  const triangleH = `calc( min(${width.contentWide}, ${width.contentSP}) / 2 * 0.2)`
  return (
    <div
      css={css`
        position: relative;
        max-width: ${width.contentWide};
        width: ${width.contentSP};
        //margin: calc(${triangleH} + 8vh) auto 0;
        margin: 0 auto;
        background: ${csxColor(color.white).fade(0.9).toString()};
        ${mq(
          `pc`,
          css`
            margin-top: calc(130px + 8vh);
          `
        )}
        ${mq(
          `sp`,
          css`
            margin-top: calc(70px + 8vh);
          `
        )}
        &::before {
          position: absolute;
          display: block;
          content: "";
          right: 0;
          top: 0;
          left: 0;
          border-bottom: solid ${csxColor(color.white).fade(0.9).toString()};
          border-right: solid
            calc(min(${width.contentWide}, ${width.contentSP}) / 2) transparent;
          border-left: solid
            calc(min(${width.contentWide}, ${width.contentSP}) / 2) transparent;
          transform: translateY(-100%);
          ${mq(
            `pc`,
            css`
            border-bottom-width: 130px;
            `
          )}
          ${mq(
            `sp`,
            css`
            border-bottom-width: 70px;
            `
          )}
        }
      `}
    >
      <div
        css={css`
          text-align: center;
          ${mq(
            `pc`,
            css`
              padding: 70px 0 0;
              font-size: 1.8rem;
              line-height: 2.22;
            `
          )}
          ${mq(
            `sp`,
            css`
              padding: min(70px, 18vw) 8.3vw 0;
              font-size: 1.4rem;
              line-height: 2;
            `
          )}
        `}
      >
        <p>
          豊かな自然に抱かれたこの島で、
          <br />
          自然の恵みを享受し、守り、繋いでいくために。
          <br />
          離島が抱える様々な課題を解決しながら、
          <br />
          持続可能でより良い世界の実現を目指し、
          <br />
          様々な企業と連携して新しい働き方、新しいビジネスを提案していきます。
        </p>
        <div>
          <Logo
            css={css`
              width: 33vw;
              max-width: 208px;
              margin-top: min(70px, 15vw);
              g {
                fill: ${color.black};
              }
            `}
          />
        </div>
      </div>
      <Points />
    </div>
  )
}

export default Introduction
